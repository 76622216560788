import { Component, OnInit } from "@angular/core";
import { BaseService } from "@app/shared/services/base.service";
import { environment } from "environments/environment";
@Component({
	selector: "app-footer",
	templateUrl: "./footer.component.html",
	styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
	clientName = "";
	currentYear: number = new Date().getFullYear();
	inLoginPage: boolean = false;

	constructor(public baseService: BaseService) {
		this.clientName = environment.clientDisplayName.toUpperCase();
		if (document.location.href.includes("login")) {
			this.inLoginPage = true;
		}
	}

	ngOnInit() {
		
	}

	isArabic() {
		return this.baseService.isArabic();
	}

	goToTop() {
		window.scrollTo({ top: 0, behavior: "smooth" });
	}
}
